<template>
  <div id="testimonials-slider-container">
    <DefineTestimonialCard v-slot="item">
      <div
        class="relative bg-white flex flex-col items-start gap-1 w-[290px] max-w-[calc(85vw-2rem)] aspect-[3/4] mb-4 pb-4 rounded-lg overflow-hidden shadow-lg cursor-pointer"
      >
        <img
          v-if="item.image"
          :src="item.image"
          :srcset="item.imageSet"
          loading="lazy"
          :alt="item.imageAlt || 'Thumbnail'"
          class="relative w-full h-[60%] max-h-[194px] overflow-hidden"
          width="290"
          height="205"
        />
        <img
          v-else
          src="/images/pfs-placeholder.svg"
          alt="Thumbnail"
          loading="lazy"
          class="bg-white relative w-full h-[60%] max-h-[194px] overflow-hidden"
          width="290"
          height="205"
        />
        <div class="px-4 text-lg truncate">
          {{ item.personName }}
        </div>
        <div class="px-4 text-xs truncate">
          {{ item.company }}
        </div>
        <div class="px-4 text-[14px] font-bold leading-5">
          {{ item.comment }}
        </div>
        <div class="px-4 text-xs truncate">
          {{ item.country }}
        </div>
      </div>
    </DefineTestimonialCard>
    <Transition name="modal">
      <AppModal
        v-if="activeVideo"
        class="modal-container fix-page"
        window-class="relative max-w-[calc(100vw-16px)] max-h-[calc(100svh-32px)] h-full w-full p-8 flex items-center justify-center"
        close-button-class="text-white"
        @click="closeVideo"
      >
        <div
          class="relative max-w-full max-h-full w-[150vmin] aspect-[16/9] py-8"
        >
          <AppButton
            class="icon-button text-white variant-invisible ml-auto h-8 w-8 !absolute right-0 top-0"
            :icon="mdiClose"
            :aria-label="$t('common.closePopup')"
            :tabindex="activeVideo ? 0 : -1"
            @click="closeVideo"
          />
          <WistiaVideo :video-id="activeVideo" />
        </div>
      </AppModal>
    </Transition>
    <Splide
      :options="{
        focus: false,
        perMove: 3,
        drag: 'free',
        autoWidth: true,
        gap: '16px',
        pagination: false,
        trimSpace: 'move',
        omitEnd: true,
        i18n: {
          slide: ''
        },
        breakpoints: {
          640: {
            arrows: false
          }
        },
        slideFocus: true,
        focusableNodes: '.splide__slide'
      }"
      class="splide-center mb-6"
    >
      <SplideSlide
        v-for="(item, index) in items"
        :key="index"
        @enter="() => openVideo(item)"
        @click="() => openVideo(item)"
      >
        <TestimonialCard v-bind="item"></TestimonialCard>
      </SplideSlide>
    </Splide>
  </div>
</template>

<script setup lang="ts">
import { mdiClose } from '@mdi/js';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';
import { createReusableTemplate } from '@vueuse/core';

import { GET_WP_TESTIMONIALS } from '@/utils/queries';
import { fixImageUrl } from '@/helpers/fixes';

const AppModal = defineAsyncComponent(
  () => import('@/async-components/App/Modal.vue')
);
const WistiaVideo = defineAsyncComponent(
  () => import('@/async-components/WistiaVideo.vue')
);

interface Testimonial {
  personName: string;
  company?: string;
  description?: string;
  country: string;
  comment: string;
  image: string;
  imageAlt: string;
  video: string;
}

interface TestimonialFromResponse {
  id: string;
  title: string;
  fields: {
    clientName: string;
    country: string;
    comment: string;
    video: string;
    company: string;
  };
  featuredImage?: {
    node?: {
      sourceUrl: string;
      altText: string;
    };
  };
}

interface TestimonialWithImageSet extends Testimonial {
  imageSet: string;
}

const [DefineTestimonialCard, TestimonialCard] =
  createReusableTemplate<TestimonialWithImageSet>();

const {
  public: { cloudflareImageProcessing: cfUrl }
} = useRuntimeConfig();

const { language } = useLanguage();

const client = useWpClient();

const activeVideo = ref<string | null>(null);

function openVideo(item) {
  if (item?.video) {
    activeVideo.value = item.video;
  }
}

function closeVideo(e) {
  activeVideo.value = null;
}

async function fetch(): Promise<Testimonial[]> {
  const gqlResponse = await client.query<{
    testimonials: {
      nodes: TestimonialFromResponse[];
    };
  }>({
    query: GET_WP_TESTIMONIALS,
    variables: {
      language: language.value
    }
  });

  const nodes = [...gqlResponse.data.testimonials.nodes].map(v => ({
    ...v
  }));

  // nodes.sort((a, b) => {
  //   return a.datetime.getTime() - b.datetime.getTime();
  // });

  return nodes.slice(0, 15).map(node => {
    return {
      personName: node.fields.clientName,
      company: node.fields.company,
      country: node.fields.country,
      comment: node.fields.comment,
      image: node.featuredImage?.node?.sourceUrl,
      imageAlt: node.featuredImage?.node?.altText,
      video: node.fields.video
    };
  });
}

const { data: asyncItems, error } = await useAsyncData<Testimonial[]>(
  async () => {
    if (process.client) {
      return [];
    }
    return await fetch();
  }
);

const clientItems = ref<Testimonial[]>([]);

onMounted(() => {
  if (!asyncItems.value?.length) {
    fetch().then(response => {
      clientItems.value = response;
    });
  }
});

const items = computed(() => {
  const items = asyncItems.value?.length ? asyncItems.value : clientItems.value;

  return items.map(item => {
    const fixedImage = item.image ? fixImageUrl(item.image) : '';
    const image = fixedImage
      ? `${cfUrl}/image?w=290&h=205&f=webp&fit=crop&image=${fixedImage}`
      : '';
    const imageSet = fixedImage
      ? `${image} 1x, ${cfUrl}/image?w=580&h=410&f=webp&fit=crop&image=${fixedImage} 2x`
      : '';
    return {
      ...item,
      image,
      imageSet
    };
  });
});

if (error.value) {
  console.error(error.value);
}
</script>

<style lang="scss">
@import '~/assets/css/splide-theme.scss';
</style>
